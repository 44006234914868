@import "./fonts/fonts.css";
@import "./config/colors.scss";
@import "./components/contact-form.scss";
@import "./components/header.scss";
@import "./components/footer.scss";
@import "./components/language-buttons.scss";
@import "./components/menu.scss";
@import "./pages/home.scss";
@import "./pages/contact.scss";
@import "./pages/domaine.scss";
@import "./pages/galerie.scss";
@import "./pages/offres.scss";
@import "./pages/reglement.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /*margin: 0 32px;*/
  font-family: "Mohave", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--beige);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container-child {
  max-width: 1200px;
  padding-left: 32px;
  padding-right: 32px;
}
.container-full, .container-child {
  margin: 0 auto;
}
.container-full {
  padding: 64px 0;
  width: 100vw;
}
.home {
  z-index: -10;
}
a {
  text-transform: uppercase;
}
a:visited {
  color: initial;
}
.bg-blur {
  transition: background-color 1s;
  color: white;
  backdrop-filter: blur(6px);
  background: linear-gradient(149.86deg, rgba(255, 255, 255, 0.3) 10.97%, rgba(255, 255, 255, 0) 102.5%);
  padding: 50px 60px;

  @media screen and (max-width: 768px) {
    padding: 32px 40px;
  }
}
// .bg-blur:hover, .bg-blur:focus {
//   background-color: rgba(255, 255, 255, 0.05);
// }

.bg-beige {
  background-color: var(--beige);
}
.bg-green {
  background-color: var(--green);
}
.bg-mauve {
  background-color: var(--mauve);
}
.green {
  color: var(--green);
}
h1, h2 {
  font-size: 2.5em;
  text-transform: uppercase;
  line-height: 1.2em;

  @media screen and (max-width: 640px) {
    font-size: 2em;
  }
}
h1 {
  font-weight: normal;
}
.bold {
  font-weight: 600;
}
h3 {
  font-size: 1.25em;
  font-weight: 600;
  text-transform: uppercase;
}
.img-background {
  min-width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -10;
  object-fit: cover;
}
.empty-first-container {
    height: 100vh;
    padding-bottom: 64px;
    margin-top: -120px;

    h1 {
      font-weight: bold;
    }

    @media screen and (min-width: 640px) {        
      h1 {
        font-size: 3em;
      }
    }

    .container-child {
      width: 100%;
      padding-bottom: 48px;

      @media screen and (max-width: 640px) {        
        padding-bottom: 60px;
      }
    }
}
.caret-down {
  position: absolute;
  bottom: 48px;
  width: 100vw;
  display: flex;
  justify-content: center;
  // cursor: pointer;

  img {
    width: 60px;
  }

  @media screen and (max-width: 640px) {
    bottom: 20px;
    
    img {
      width: 30px;
    }
  }
}
.cta {
  padding: 1.5rem 2rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  transition: background-color 500ms;
}
.cta-green {
  background-color: var(--green);
  color: var(--beige);
  transition: background-color 500ms;

  &:hover {
    background-color: #373E2D;
  }
}
.cta-red {
  background-color: var(--orange);
  color: var(--beige);
  
  &:hover {
    background-color: #B74029;
    transition: background-color 500ms;
  }
}
.cta.bg-blur:hover {
  background: linear-gradient(120.86deg, rgba(255, 255, 255, 0.3) 10.97%, rgba(255, 255, 255, 0) 102.5%);
  transition: background-color 500ms;
}
.cta-beige {
  background-color: var(--beige);
  color: var(--green);
  
  &:hover {
    background-color: #E5D2B9;
    transition: background-color 500ms;
  }
}
.white {
  color: white;
}