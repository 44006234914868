.home {
    .contact-form-section {
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .home-bloc-description {
        @media screen and (min-width: 768px) {
            transform: translateY(-480px);
            margin-bottom: -400px;
        }
        
        h2 {
            max-width: 400px;
            margin: 0 auto;
        }
    }
    .carte-france {
        @media screen and (min-width: 768px) {
            transform: translateY(-80px);
        }
    }
    .img-vue-aerienne {
        @media screen and (min-width: 768px) {
            transform-origin: top left;
            transform: scale(1.25);
        }
    }
}