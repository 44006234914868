@font-face {
    font-family: 'Mohave';
    src: url('./Mohave-Bold.ttf');
    src:
    url('./Mohave-Bold.ttf') format('ttf');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mohave';
    src: url('Mohave-Bold.eot');
    src: url('Mohave-Bold.eot?#iefix') format('embedded-opentype'),
        url('Mohave-Bold.woff2') format('woff2'),
        url('Mohave-Bold.woff') format('woff'),
        url('Mohave-Bold.svg#Mohave-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mohave';
    src: url('Mohave-Light.eot');
    src: url('Mohave-Light.eot?#iefix') format('embedded-opentype'),
        url('Mohave-Light.woff2') format('woff2'),
        url('Mohave-Light.woff') format('woff'),
        url('Mohave-Light.svg#Mohave-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mohave';
    src: url('Mohave-Medium.eot');
    src: url('Mohave-Medium.eot?#iefix') format('embedded-opentype'),
        url('Mohave-Medium.woff2') format('woff2'),
        url('Mohave-Medium.woff') format('woff'),
        url('Mohave-Medium.svg#Mohave-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mohave';
    src: url('Mohave-Regular.eot');
    src: url('Mohave-Regular.eot?#iefix') format('embedded-opentype'),
        url('Mohave-Regular.woff2') format('woff2'),
        url('Mohave-Regular.woff') format('woff'),
        url('Mohave-Regular.svg#Mohave-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mohave';
    src: url('Mohave-SemiBold.eot');
    src: url('Mohave-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Mohave-SemiBold.woff2') format('woff2'),
        url('Mohave-SemiBold.woff') format('woff'),
        url('Mohave-SemiBold.svg#Mohave-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

