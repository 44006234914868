.contact-form-section {
    width: 100%;
    display: flex;
    align-items: flex-end;

    h2 {
        max-width: 550px;
        color: var(--green);
        margin-bottom: 2rem;
    }

    @media screen and (max-width: 768px) {
        // padding-left: 32px;
        // padding-right: 32px;
    }

    .contact-form {
        max-width: 718px;
    }
    .container-child {
        margin: 0 auto;
        padding: 0;
        width: 100%;
    }
    input[type=text] {
        height: 44px;
    }
    textarea {
        // height: 174px;
        padding-top: 16px;
    }
    input[type=text], textarea {
        margin-bottom: 16px;
        padding-left: 16px;
        background: rgba(73, 82, 60, 0.3);

        &::placeholder {
            color: rgba(73, 82, 60, 0.5);
            text-transform: uppercase;
        }
        &:focus {
            outline: none;
        }
    }
    input[type=submit] {
        cursor: pointer;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
    }
    .form-message {
        color: var(--mauve);
        font-weight: bold;
        text-transform: uppercase;
        // opacity: 0;
    }
}