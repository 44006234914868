.reglement h3 {
	text-transform: uppercase;
}
.reglement {
    color: var(--green);
}
.reglement-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 64px;
    break-inside: avoid-column;

    h3 {
        font-weight: bold;
        margin-bottom: 8px;
    }
    img {
        margin-right: 16px;
    }
}