.menu {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: var(--mauve);
    display: none;
    z-index: 100;

    .menu-links {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        height: 100vh;
        
        a {
            color: white;
            font-size: 2em;
            line-height: 1.5em;
        }
    }
}