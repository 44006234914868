footer {
    background: var(--green);
    color: var(--beige);
    width: 100vw;
    left: 0;
    //bottom: 0;
    position: absolute;
    padding: 64px 0;
    text-transform: uppercase;
    // margin-top: 5rem;

    a {
        color: var(--beige) !important;
    }
    & > div {
        margin: 0 auto;
        max-width: 1086px;
        // display: flex;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        .footer-logo {
            // width: 260px;

            @media screen and (max-width: 768px) {
                width: 100px;    
            }
        }
    }

    .footer-links {
        list-style-type: none;
        padding-left: 0;

        & li:hover {
            text-decoration: underline;
        }
    }

    .picto-social {
        background-image: url(../../../public/images/elements/picto-fb-mauve.svg);
        background-size: contain;
        background-position: center;
        width: 42px;
        height: 42px;
    }
    .picto-fb {
        background-image: url(../../../public/images/elements/picto-fb-mauve.svg);

        &:hover {
            background-image: url(../../../public/images/elements/picto-fb-white.svg);
        }
    }
    .picto-ig {
        background-image: url(../../../public/images/elements/picto-ig-mauve.svg);
        &:hover {
            background-image: url(../../../public/images/elements/picto-ig-white.svg);
        }
    }
}
