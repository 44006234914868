.page-galerie {
    padding-top: 40px;
}
.pswp__counter, .pswp__button--zoom, .pswp__button--close {
    display: none !important;
}
.pswp {
    --pswp-icon-stroke-color: none !important;
    --pswp-icon-stroke-width: 0 !important;
}
.pswp__button--arrow .pswp__icn {
    width: 50px !important;
    height: 50px !important;
}
.pswp__button--arrow--prev .pswp__icn {
    left: 24px !important;
}
.pswp__button--arrow--next .pswp__icn {
    right: 24px !important;
}