.page-offres {
    .offres-main-title {
        margin-top: 128px;
        padding-top: 64px;
        padding-bottom: 32px;
        
        @media screen and (min-width: 768px) {
            margin-bottom: 140px;
        }
        @media screen and (max-width: 768px) {
            background-color: var(--mauve);
            margin-top: 0;
        }
    }
    p {
        margin-bottom: 16px;
    }
    .cards {
        transform: translateY(0px);

        @media screen and (min-width: 768px) {
            transform: translateY(-140px);
            margin-bottom: -150px;
        }

        .card {
            padding: 32px 48px;
            break-inside: avoid-column;
            margin-bottom: 24px;

            &:nth-child(2),
            &:nth-child(1) {
                @media screen and (max-width: 768px) {
                    margin-bottom: 80px;
                }
            }


            li {
                list-style: inside;
                text-transform: uppercase;
            }
            p {
                margin-top: 16px;
                line-height: 1.1em;
            }
            .offre-picto {
                transform: translateY(-90px);
                margin-bottom: -60px;
            }
        }
    }
}