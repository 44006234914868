header {
    position: fixed;
    width: 100vw;
    color: white !important;
    transition: top 0.5s;

    & div.container-full {
        z-index: 100;
        padding: 0;
        width: 100vw;
    }
    & div.container-child {
        display: flex;
        justify-content: space-between;
        height: 140px;
        align-items: center;
        z-index: 100;
        font-weight: 500;

        @media screen and (max-width: 768px) {
            justify-content: center;
            height: 120px;
        }
    }
    a {
        color: white;
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
    .header-logo {
        width: 100px;
    }
    .header-link {
        display: block;
        width: 15%;
        color: white !important;
        font-size: 1.25em;

        &:hover {
            font-weight: bold;
        }

        @media screen and (max-width: 768px) {
            &:nth-child(1),
            &:nth-child(2), 
            &:nth-last-child(1), 
            &:nth-last-child(2) {
                display: none !important;
            }

            width: 80px;
        }
    }
    .header-link:nth-last-child(1),
    .header-link:nth-last-child(2) {
        display: block;
        width: 15%;
        text-align: right;
    }
    .header-link:nth-last-child(3) {
        text-align: center;
        display: flex;
        justify-content: center;
    }
    .menu-burger {
        display: none;
        position: fixed;
        right: 32px;
        top: 44px;
        width: 32px;
        cursor: pointer;
        
        @media screen and (max-width: 768px) {
            display: block;
        }
    }
}
